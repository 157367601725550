<template>
  <div class="d-flex justify-content-center px-5">
    <router-link to="Dashboard">
      <img
        src="@/assets/logo.png"
        alt="Logo"
        title="Integratiepartners logo"
        height="75"
        width="75"
        class="img-fluid"
    /></router-link>
  </div>
</template>

<script>
export default {
  name: "SidebarLogoHeader",
};
</script>

<style></style>
