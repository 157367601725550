<template>
  <div class="admin__main-container position-relative">
    <SideBar
      :width="width"
      @toggle-collapse="collapsed = $event"
      :collapsed="collapsed"
    />
    <div class="content-container" :style="{ paddingLeft: containerPadding }">
      <router-view />
    </div>
  </div>
</template>

<script>
import SideBar from "@/layouts/SideBar";

export default {
  components: {
    SideBar,
  },
  mounted() {
    this.onResize();
    // this.observeNavHeight();
    window.addEventListener("resize", this.onResize);
  },

  data() {
    return {
      width: 250,
      height: 0,
      collapsed: false,
      onMobile: false,
    };
  },
  computed: {
    containerPadding() {
      return this.collapsed || this.onMobile ? "50px" : "250px";
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.onMobile = true;
        this.collapsed = true;
      } else {
        this.onMobile = false;
        this.collapsed = false;
      }
    },
    observeNavHeight() {
      let observer = new ResizeObserver((entries) => {
        let entry = entries[0];
        this.height = entry.contentRect.height;
      });
      observer.observe(document.getElementById("myTopnav"), {
        box: "border-box",
      });
    },
  },
};
</script>

<style>
.v-sidebar-menu .vsm--link {
  font-size: 14px !important;
}
</style>

<style scoped lang="scss">
body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  height: 100%;
}
.main-content {
  margin: 10px 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
