<template>
  <sidebar-menu
    :menu="menu"
    :width="`${width}px`"
    @toggle-collapse="$emit('toggle-collapse', $event)"
    @item-click="onItemClick"
    :collapsed="collapsed"
  />
</template>

<script>
import firebase from "firebase";
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import SidebarLogoHeader from "./SidebarLogoHeader.vue";
export default {
  name: "SideBar",
  props: ["width", "collapsed"],
  components: {
    SidebarMenu,
  },
  data() {
    return {
      menu: [
        {
          component: SidebarLogoHeader,
        },
        {
          header: "Main Navigation",
          hiddenOnCollapse: true,
        },

        {
          href: "/Dashboard",
          title: "Dashboard",
          icon: "fa fa-university",
        },
        {
          href: "/Klanten",
          title: "Klanten",
          icon: "fa fa-users ",
        },

        {
          title: "Cursussenbeheren",
          icon: "fa fa-gear",
          child: [
            {
              href: "/Cursussenbeheren",
              title: "Cursussen bekijken/aanmaken",
              icon: "fa fa-calendar",
            },
            {
              href: "/Cursusinplannen",
              title: "Cursussen inplannen",
              icon: "fa fa-calendar",
            },
          ],
        },

        {
          title: "Blogsbeheren",
          icon: "fa fa-gear",
          child: [
            {
              href: "/Blogs",
              title: "Blog bekijken",
              icon: "fa fa-calendar",
            },
            {
              href: "/Blogtoevoegen",
              title: "Blog toevoegen",
              icon: "fa fa-calendar",
            },
          ],
        },

        {
          title: "Bestellingen",
          icon: "fa fa-gear",
          child: [
            {
              href: "/Bestellingen",
              title: "Bestellingen",
              icon: "fa fa-shopping-cart",
            },
          ],
        },

        {
          title: "Instellingen",
          icon: "fa fa-gear",
          child: [
            {
              href: "/Upload",
              title: "Upload planning",
              icon: "fa fa-download",
            },
            {
              href: "/Mailtriggers",
              title: "Mailtriggers instellingen",
              icon: "fa fa-download",
            },
            {
              href: "/Mailtemplates",
              title: "Mailtemplate instellingen",
              icon: "fa fa-download",
            },

            {
              href: "/Categorie",
              title: "Categorieen",
              icon: "fa fa-download",
            },

            {
              href: "/BlogCategorie",
              title: "BlogCategorie",
              icon: "fa fa-download",
            },

            {
              href: "/Folders",
              title: "Alle Folders bekijken",
              icon: "fa fa-file-invoice",
            },

            {
              href: "/Status",
              title: "Status",
              icon: "fa fa-download",
            },
            {
              href: "/Certificaat",
              title: "Certificaten",
              icon: "fa fa-download",
            },

            {
              href: "/Mailinstellingen",
              title: "Mailinstellingen",
              icon: "fa fa-download",
            },
            {
              href: "/Cronjobs",
              title: "Cronjobs",
              icon: "fa fa-download",
            },
          ],
        },

        {
          title: "Uitloggen",
          icon: "fa fa-power-off",
        },
      ],
    };
  },
  methods: {
    async logOut() {
      await firebase.auth().signOut();
      location.reload();
    },
    onItemClick(event, item) {
      if (item.title === "Uitloggen") this.logOut();
    },
  },
};
</script>
